<template>
  <v-lazy
    :min-height="avatarSize"
    :options="{ 'threshold': 0.1 }"
    transition="fade-transition"
  >
    <v-chip
      v-bind="$attrs"
      class="pl-0"
      :to="disableClick || !category ? undefined : { name: 'assets', query: { category: category?._id, tab: 'LIST' } }"
      :color="!variant || variant === 'tonal' ? category?.marker?.color : undefined"
      :variant="variant"
      :density="density ?? 'comfortable'"
    >
      <template #prepend>
        <v-avatar :size="avatarSize" class="mr-2" :color="category?.marker?.color ?? 'grey'">
          <span class="text-white font-weight-medium text-caption">{{ category?.marker?.description ?? '?' }}</span>
        </v-avatar>
      </template>

      <span class="text-high-emphasis">{{ category?.description ?? $t('unknown') }}</span>
    </v-chip>
  </v-lazy>
</template>

<script setup lang="ts">
const props = defineProps<{
  category?: Pick<AssetCategory, "description" | "marker" | "_id"> | null,
  density?: "comfortable" | "compact" | "default"
  variant?: "text" | "outlined" | "tonal"
  disableClick?: boolean
}>()
const { category, density, disableClick, variant } = toRefs(props)

const avatarSize = computed(() => {
  if (density?.value === "compact") return "1.25rem"
  if (density?.value === "comfortable" || !density.value) return "1.5rem"
  return "2rem"
})
</script>